
// import SVGBackground from '../../assets/svg/lorembg.svg';
export default {
  name: 'TextBlockComponent',
  components: {
    // SVGBackground
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
